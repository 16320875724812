<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">Update timezone</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6 input-style base-input">
      <v-form
        v-model="valid"
        ref="timeZoneForm"
        @submit.prevent="submitHandler"
      >
        <v-autocomplete
          v-model="obj.timeZone"
          :items="Intl.supportedValuesOf('timeZone')"
          dense
          outlined
          placeholder="Select timezone"
          :hide-details="true"
          background-color="white"
          class="mt-4"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <span class="d-inline-block mr-2">{{ data.item }}</span>
            </v-chip>
          </template>
        </v-autocomplete>

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          type="submit"
          :loading="loading"
          :disabled="loading"
          ><span class="white--text font-16">Update</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    count: "",
    obj: {},
    items: [],
    valid: false,
    keyDateUpdated: false,
    loading: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.timeZoneForm.validate();
      if (!valid) {
        return;
      }

      this.loading = true;
      let formData = new FormData();

      //console.log("this.obj.currency", this.obj.currency);
      formData.append("timeZone", this.obj.timeZone);
      let res = await this.$axios.patch(process.env.VUE_APP_PROFILE, formData);
      if (res.data.success) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Timezone edited successfully!",
          color: "success",
        });
        // set currency
        // res.data.user.currencyName = res.data.user.currency.currency;
        // res.data.user.currencySymbol = res.data.user.currency.symbol;

        this.$store.dispatch("setProfile", res.data.user);
        this.$emit("close");
        this.$emit("update");
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: res.data.message || "Something went wrong!",
          color: "error",
        });
      }
    },
    async getProducts() {
      // ?searchWord=sp
      let { data } = await this.$axios.get(process.env.VUE_APP_CURRENCIES);
      this.items = data.currencies;
    },
  },
  async created() {
    await this.getProducts();
    this.obj = { ...this.item };
  },
};
</script>

<style lang="scss" scoped>
.item-counts {
  span {
    padding: 5px 10px;
    background: #d2e8e3;
    border-radius: 16px;
    transition: 0.3s;
  }
}
.v-autocomplete__content {
  .v-list {
    background: white !important;
  }
}
.theme-active {
  background: $primary !important;
  color: white;
}
</style>
