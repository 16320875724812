<template>
  <div class="settings">
    <!-- desktop head -->
    <div
      v-if="!isMobile"
      class="max-width d-flex align-center justify-space-between"
    >
      <span class="black--text font-24">Settings</span>
    </div>

    <!-- desktop head -->
    <div class="pt-0 pb-5 text-center" v-if="isMobile">
      <span class="black--text font-24">Settings</span>
    </div>

    <div class="settings__content max-width">
      <v-row :class="isMobile ? 'col-reverse' : ''">
        <v-col cols="12" md="6" class="pa-1">
          <div class="settings__cover">
            <span class="font-20 font-500 black--text d-block mb-5"
              >Account Details</span
            >
            <div class="d-flex align-center justify-space-between">
              <span class="font-18 black--text">{{ profile.name }}</span>
              <!-- <div
                class="d-flex align-center greenbg py-1 px-2 cursor-pointer"
                @click="accountDialog = true"
              >
                <span class="font-18 black--text">Edit</span>
                <img
                  class="ml-2"
                  :src="require('@/assets/images/icons/arrow-right.svg')"
                  alt=""
                />
              </div> -->
            </div>
          </div>
        </v-col>

        <!-- subscription -->
        <v-col
          v-if="profile.role !== 'admin'"
          cols="12"
          md="6"
          class="pa-1"
        >
          <div class="settings__cover">
            <span class="font-20 font-500 black--text d-block mb-5"
              >Subscription</span
            >
            <div
              class="d-flex align-center justify-space-between cursor-pointer"
              @click="$router.push('/my-plan')"
            >
              <span class="font-18 black--text user-plan">
                {{ (profile.plan && profile.plan.title) || "Free Plan" }}
              </span>
              <!-- <span class="font-18 black--text font-700">+ Upgrade Plan</span> -->
            </div>
          </div>
        </v-col>

        <!-- currency -->
        <v-col
          v-if="profile.role !== 'admin'"
          cols="12"
          md="6"
          class="pa-1"
        >
          <div class="settings__cover">
            <span class="font-20 font-500 black--text d-block mb-5"
              >Currency</span
            >
            <div class="d-flex align-center justify-space-between">
              <span class="font-18 black--text">
                <!-- Currency type -->
                <span class="font-600"
                  >{{
                    profile.currency
                      ? profile.currency.symbol
                      : currencyName
                      ? currencyName.currency
                      : ""
                  }}
                </span>
              </span>
              <span
                class="font-18 black--text font-700 cursor-pointer"
                @click="showCurrencyDialog = true"
                >Edit</span
              >
            </div>
          </div>
        </v-col>

        <!-- Time Zone -->
        <v-col
          v-if="profile.role !== 'admin'"
          cols="12"
          md="6"
          class="pa-1"
        >
          <div class="settings__cover">
            <span class="font-20 font-500 black--text d-block mb-5"
              >Time zone</span
            >
            <div class="d-flex align-center justify-space-between">
              <span class="font-18 black--text">
                <span class="font-600">{{ profile.timeZone || "" }} </span>
              </span>
              <span
                class="font-18 black--text font-700 cursor-pointer"
                @click="showTimeZoneDialog = true"
                >Edit</span
              >
            </div>
          </div>
        </v-col>

        <!-- account -->
        <v-col cols="12" md="6" class="pa-1">
          <div class="settings__cover">
            <span class="font-20 font-500 black--text d-block mb-5"
              >Account</span
            >
            <v-list class="pa-0">
              <!-- email tab -->
              <email-tab class="settings__cover__border pb-2 mb-3"></email-tab>
              <!-- password tab -->
              <!-- <password-tab
                class="settings__cover__border pb-2 mb-3 cursor-pointer"
              ></password-tab> -->
              <!-- delete tab -->
              <delete-tab
                v-if="profile.role !== 'admin'"
                class="cursor-pointer"
              ></delete-tab>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="accountDialog"
      max-width="500"
      content-class="white radius-25 py-6 px-14"
    >
      <account-details @close="accountDialog = false"></account-details>
    </v-dialog>

    <!-- currency dialog -->
    <v-dialog
      v-model="showCurrencyDialog"
      max-width="400"
      content-class="radius-25"
    >
      <currency-dialog
        :item="profile"
        @close="showCurrencyDialog = false"
      ></currency-dialog>
    </v-dialog>

    <!-- timezone dialog -->
    <v-dialog
      v-model="showTimeZoneDialog"
      max-width="400"
      content-class="radius-25"
    >
      <time-zone-dialog
        :item="profile"
        @close="showTimeZoneDialog = false"
      ></time-zone-dialog>
    </v-dialog>
  </div>
</template>

<script>
import AccountDetails from "../../components/dialogs/AccountDetails.vue";
import CurrencyDialog from "../../components/dialogs/CurrencyDialog.vue";
import TimeZoneDialog from "../../components/dialogs/TimeZoneDialog.vue";
import DeleteTab from "./components/DeleteTab.vue";
import EmailTab from "./components/EmailTab.vue";
// import PasswordTab from "./components/PasswordTab.vue";

export default {
  components: {
    EmailTab,
    // PasswordTab,
    DeleteTab,
    AccountDetails,
    CurrencyDialog,
    TimeZoneDialog,
  },

  data: () => ({
    accountDialog: "",
    showCurrencyDialog: false,
    showTimeZoneDialog: false,
    currencyName: "",
  }),
  async created() {
    let { data } = await this.$axios.get(process.env.VUE_APP_CURRENCIES);
    this.currencyName = data.currencies.find(
      (curr) => curr._id == this.profile.currency._id
    );
  },
};
</script>

<style lang="scss">
.settings {
  padding-top: 30px;

  &__content {
    padding: 30px 0px;
  }

  &__cover {
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 19px 28px;
    background: #fafafa;
    height: 100%;

    &__border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .v-list-group__header {
    padding: 0px;
  }

  @media (max-width: 960px) {
    &__content {
      padding: 0px 12px;
    }
  }
}
.theme--light.v-list {
  background: transparent;
}
</style>
