<template>
  <div>
    <span class="font-32 black--text d-block mb-6">Account Details</span>
    <div>
      <upload-btn
        class="upload-logo"
        text="Logo image"
        @click="uploadImage = true"
        :image="profile.logo? profile.logo.url : ''"
        :image64="profile.logo? profile.logo.url : imgDataUrl"
        :key="fileKey"
        :cropImage="true"
      ></upload-btn>
      <span class="font-18 d-block black--text mt-2">Logo Image</span>
      <span class="font-15 d-block greytext--text"
        >JPG, <span class="font-600">PNG*</span></span
      >
    </div>
    <!-- username -->
    <v-text-field
      class="mt-4"
      v-model="obj.name"
      label="Username"
      dense
      :hide-details="true"
    ></v-text-field>
    <!-- username -->
    <v-text-field
      class="mt-7"
      v-model="obj.password"
      label="password"
      type="password"
      dense
      :hide-details="true"
    ></v-text-field>
    <!-- address -->
    <v-textarea
      class="mt-8"
      v-model="obj.address"
      label="Address"
      dense
      rows="3"
      :hide-details="true"
    ></v-textarea>
    <div class="d-flex align-center mt-5 justify-center">
      <v-btn
        width="120px"
        height="40px"
        color="grey"
        class="mr-3"
        @click="$emit('close')"
      >
        <span class="font-18 font-500 white--text">Cancel</span>
      </v-btn>
      <v-btn width="120px" height="40px" color="primary" @click="submitHandler">
        <span class="font-18 font-500 white--text">Done</span>
      </v-btn>
    </div>

    <v-dialog
      v-model="uploadImage"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noSquare="true"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="uploadImage = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import UploadBtn from "../base/UploadBtn.vue";
import myUpload from "vue-image-crop-upload";

export default {
  components: { UploadBtn, myUpload },
  data: () => ({
    file: {},
    fileKey: false,
    obj: {},

    imgDataUrl: "",
    uploadImage: false,
  }),
  computed: {
    role() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    uploadFile(file) {
      this.file.url = URL.createObjectURL(file);
      this.obj.file = file;
      this.fileKey = !this.fileKey;
    },
    deleteFile() {
      this.file = {};
      this.fileKey = !this.fileKey;
    },
    async submitHandler() {
      let payload = new FormData();
      for (let key in this.obj) {
        payload.append(key, this.obj[key]);
      }
      const res = await this.$axios.patch(process.env.VUE_APP_PROFILE, payload);
      if (res.data) {
        // update profile
        this.$store.dispatch("setProfile");
        this.$store.dispatch("showSnack", {
          text: "Registered successfully",
          color: "success",
        });
        this.obj.password = "";
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.uploadImage = false;

      this.uploadLogo(imgDataUrl)
        .then((res) => {
          // console.log(res, "res from upload logo");
          // this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // async getProfile() {
    //   let urlApi = process.env.VUE_APP_PROFILE;
    //   // ToDo: make sure that profile endpoint is the same for users and admins
    //   // if (this.role == "user") {
    //   //   urlApi = process.env.VUE_APP_PROFILE;
    //   // }
    //   let { data } = await this.$axios.get(urlApi);
    //   if (data.success) {
    //     this.$store.dispatch("setProfile", data[this.role]);
    //   }
    // },
  },
  created() {
    this.obj = {
      name: this.profile.name,
      address: this.profile?.location.address ?? "",
    };
  },
};
</script>

<style lang="scss">
.upload-logo {
  height: 100px;
  width: 100px !important;
}
</style>
