<template>
  <div>
    <div
      class="d-flex align-center justify-space-between"
      @click="dialog = true"
    >
      <div>
        <span class="font-18 black--text d-block">Delete your Account</span>
      </div>
      <img
        width="9px"
        height="15px"
        :src="require('@/assets/images/icons/arrow-right.svg')"
      />
    </div>

    <v-dialog v-model="dialog" content-class="white pa-4" max-width="450px">
      <div>
        <span class="font-18 black--text d-block font-500"
          >Confirm Account Deletion</span
        >
        <p class="font-14 icon-black--text mt-2 mb-6">
          Type "delete" to delete your account. <br />
          All contacts and other data associated with this account will be
          permanently deleted. this cannot be undone
        </p>

        <v-text-field
          v-model="value"
          placeholder=""
          label=""
          dense
          :hide-details="true"
        ></v-text-field>
      </div>

      <div class="d-flex align-center justify-end mt-10">
        <v-btn
          class="mr-4"
          width="80px"
          outlined
          height="30px"
          color="grey"
          @click="dialog = false"
        >
          <span class="font-14 font-400">Cancel</span>
        </v-btn>

        <v-btn
          width="80px"
          :disabled="value !== 'delete'"
          height="30px"
          color="secondary"
          @click="deleteAccount"
        >
          <span
            class="font-14 font-400"
            :class="value == 'delete' ? 'white--text' : 'black--text'"
            >Delete</span
          >
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    value: "",
  }),
  methods: {
    deleteAccount() {
      this.$axios
        .delete(process.env.VUE_APP_PROFILE)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("logout");
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  watch: {
    dialog: {
      handler() {
        this.value = "";
      },
    },
  },
};
</script>

<style></style>
